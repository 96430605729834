.suggestions {
  position: absolute;
  background: #fff;

  border-radius: 5px;
  z-index: 10;
  margin-right: 50%;
  padding: 0;
  list-style: none;
  text-align: left;
}

.suggestionsDestination {
  position: absolute;
  background: #fff;
  border-radius: 5px;
  z-index: 10;
  margin-right: 10%;
  padding: 0;
  list-style: none;
  text-align: left;
}

@media screen and (max-width: 700px) {
  .suggestions {
    margin-right: 5%;
  }
}

/* 
  border: 1px solid rgb(83, 103, 146);
  top: 0;
  transform: translateY(40px);
  width: 300px;
 */
